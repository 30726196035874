function joazin(id, opcoes){
    if($(id).children().length > 1){
        $(id).owlCarousel(opcoes)
    }else{
        $(id).addClass('owl-loaded');
    }
}

joazin('#clientes-carousel',{
    loop:true,
    autoplay:true,
    nav:true,
    navText:[
        '<span class="control-banner pull-left icon icon-arrow-left" title="Item Anterior"></span>',
        '<span class="control-banner pull-right icon icon-arrow-right" title="Próximo item"></span>'
    ],
    responsive:{
        0:{
            items:1
        },
        576:{
            items:2
        },
        768:{
            items:2,
            margin: 20
        },
        992:{
            items:3,
            margin:20,
        },
        1200:{
            items:4,
            margin:20,
        }
    }
})